import React, { FC } from 'react';
import { graphql } from 'gatsby';
import RelatedProducts from 'containers/RelatedProducts';
import SocialMedia from 'containers/SocialMedia';
import TipCards from 'containers/TipCards';
import { useScreenService } from 'hooks/useScreenService';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Breadcrumbs from 'components/Breadcrumbs';

import './InsectDetailPage.scss';

const InsectDetailPage: FC<{ data: InsectDetailPageTypes.InsectDetailPageType }> = ({
  data: {
    insectDetails: {
      pageName,
      seo,
      urls,
      title,
      introduction,
      bodyText,
      tipCard,
      socialMediaText,
      socialMediaShare,
    },
    allProductDetails: { nodes: products },
    productDetails: {
      relatedProducts: { relatedProductsTitle },
    },
  },
}) => {
  const { keywords, description } = seo;
  const seoTitle = seo.title;
  const { isMobile } = useScreenService();

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />

      <Breadcrumbs />
      <Container fluid noPadding className="insect-detail">
        <Container className="insect-detail__wrapper">
          <Typography variant="heading2" customClass="insect-detail__title" weight="medium">
            {title}
          </Typography>
          <Typography
            variant="paragraph3"
            customClass="insect-detail__introduction"
            dangerouslySetInnerHTML={introduction}
          />
          <Typography
            variant="paragraph1"
            customClass="insect-detail__text"
            dangerouslySetInnerHTML={bodyText}
          />
        </Container>
        {!isMobile ? <TipCards cards={tipCard} /> : null}
        <Container className="insect-detail__icons">
          <Typography variant="label" customClass="insect-detail__icons--text">
            {socialMediaText}
          </Typography>
          <SocialMedia socialMediaShare={socialMediaShare} articleTitle={title} />
        </Container>
      </Container>
      <RelatedProducts
        products={products}
        sectionTitle={relatedProductsTitle}
        customClass="insect-detail__related-solutions"
      />
      {isMobile ? <TipCards cards={tipCard} customClass="insect-detail__tip-cards" /> : null}
    </Layout>
  );
};

export const query = graphql`
  query InsectDetailPageQuery($lang: String, $url: String, $relatedProductsLinks: [String]) {
    insectDetails(lang: { eq: $lang }, url: { eq: $url }) {
      pageName
      seo {
        ...SeoFragment
      }
      urls {
        href
        lang
      }
      ...InsectDetailFragment
    }
    productDetails {
      relatedProducts {
        relatedProductsTitle
      }
    }
    allProductDetails(filter: { url: { in: $relatedProductsLinks } }) {
      nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default InsectDetailPage;
